import { ReactElement, useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';

import { links } from '@/config/links';
import OptimizedImage from '@/components/optimized-image';
import { useIntersectionObserver } from '@/hooks/use-intersection-observer';

declare global {
  interface Window {
    dataLayer: any;
  }
}

interface TopBannerProps {
  bannerType: 'mobile' | 'desktop';
  onClose: Dispatch<SetStateAction<boolean>>;
}

const OFFER_STARTS = new Date('2024/12/16 10:00:00 GMT+02:00').getTime();
const OFFER_ENDS = new Date('2024/12/19 10:00:00 GMT+02:00').getTime();
const OFFERS_ANALYTICS = 'Christmas Sale 2024';
const OFFERS_ANALYTICS_MOBILE = 'Christmas Sale 2024 (CleanMyPhone)';

function handleAnalytics(type: string, isMobile: boolean) {
  const analyticsName = isMobile ? OFFERS_ANALYTICS_MOBILE : OFFERS_ANALYTICS;

  window.dataLayer.push({
    event: `promotion${type}`,
    ecommerce: {
      [`promo${type}`]: {
        promotions: [
          {
            name: analyticsName,
          },
        ],
      },
    },
  });
}

function TopBanner(props: TopBannerProps): ReactElement {
  const { bannerType, onClose: setIsTopBannerClosed } = props;

  const [isOfferActive, setIsOfferActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const bannerRefMobile = useRef<HTMLDivElement>(null);
  const bannerRefDesktop = useRef<HTMLDivElement>(null);
  const { isIntersecting: isIntersectingMobile } = useIntersectionObserver(bannerRefMobile, {}, true);
  const { isIntersecting: isIntersectingDesktop } = useIntersectionObserver(bannerRefDesktop, {}, true);

  const rootClassname = clsx('promo-banner', {
    'promo-banner--mobile p-0 lg:px-12': isMobile,
    'lg:hidden': bannerType == 'mobile',
    'hidden lg:block': bannerType == 'desktop',
  });

  const closeOffer = () => {
    setIsTopBannerClosed(true);
    sessionStorage.setItem('promo-banner', 'closed');
  };

  useEffect(() => {
    const currentDate = Date.now();
    const { userAgent } = navigator;
    const isActiveDate = currentDate > OFFER_STARTS && currentDate < OFFER_ENDS;

    setIsMobile(userAgent.includes('Mobi') ? true : false);
    setIsOfferActive(isActiveDate);
  }, []);

  useEffect(() => {
    if (isIntersectingMobile || isIntersectingDesktop) {
      handleAnalytics('View', isMobile);
    }
  }, [isIntersectingMobile, isIntersectingDesktop, isMobile]);

  if (!isOfferActive) {
    return <></>;
  }

  if (isMobile) {
    return (
      <aside className={rootClassname} aria-labelledby="banner-promo__title" ref={bannerRefMobile}>
        <button
          className="absolute top-1/2 mr-4 hidden -translate-y-1/2 transform opacity-40 hover:opacity-80 lg:block"
          type="button"
          onClick={closeOffer}
        >
          <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
            <g opacity=".4" stroke="#fff" strokeWidth="2">
              <path d="m3 3 16 16" />
              <path d="m19 3-16 16" />
            </g>
          </svg>
        </button>

        <OptimizedImage
          className="text-0 mx-auto block w-max lg:mx-0 lg:hidden"
          src="/images/top-banner/cmp.png"
          width="360"
          height="183"
        />

        <div className="mx-auto flex min-h-[104px] w-full max-w-[1120px] flex-col items-center justify-between px-3 pb-3 lg:flex-row lg:p-0">
          <OptimizedImage
            className="text-0 mx-5 hidden w-max self-end lg:block"
            src="/images/top-banner/img.png"
            width="139"
            height="104"
          />
          <div className="mb-3 lg:mb-0 lg:mr-4">
            <p className="text-center text-[20px] font-medium lg:text-[24px] lg:font-semibold lg:leading-snug">
              ホリデーセール
            </p>
            <p className="m-0 w-full text-center text-lg leading-[1.3] lg:text-[18px] lg:leading-tight">
              お祝いください。お掃除は私たちにお任せ
            </p>
          </div>
          <OptimizedImage
            className="text-0 mr-5 hidden h-auto w-max max-w-[190px] lg:block"
            src="/images/top-banner/cmp.png"
            width="190"
            height="95"
          />
          <a
            className="mb-4 w-full flex-shrink-0 rounded-[10px] bg-[#FB35B5] px-8 py-[11px] text-center text-[16px] font-semibold leading-[1.6] text-white transition-colors duration-200 ease-in-out hover:bg-[#FC53C0] active:bg-[#D52D9A] sm:w-[220px] lg:mb-0"
            href="https://macpaw.onelink.me/Mw8m"
            target="_blank"
            rel="noreferrer"
            onClick={() => handleAnalytics('Click', isMobile)}
            data-banner-cta
          >
            30%割引で取得
          </a>
          <button
            className="block w-full flex-shrink-0 rounded-[10px] border-2 border-[rgba(255,255,255,0.2)] bg-transparent px-8 py-[9px] text-center text-[16px] font-semibold leading-[1.6] text-white transition-colors duration-200 ease-in-out hover:border-[rgba(255,255,255,0.6)] active:border-[rgba(255,255,255,0.5)] sm:w-[220px] lg:hidden"
            type="button"
            onClick={closeOffer}
          >
            を閉じる
          </button>
        </div>
      </aside>
    );
  }

  return (
    <aside className={rootClassname} aria-labelledby="banner-promo__title" ref={bannerRefDesktop}>
      <button
        className="absolute top-1/2 mr-4 hidden -translate-y-1/2 transform opacity-40 hover:opacity-80 lg:block"
        type="button"
        onClick={closeOffer}
      >
        <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
          <g opacity=".4" stroke="#fff" strokeWidth="2">
            <path d="m3 3 16 16" />
            <path d="m19 3-16 16" />
          </g>
        </svg>
      </button>

      <OptimizedImage
        className="text-0 mx-auto mb-3 block w-max lg:mx-0 lg:mb-0 lg:hidden"
        src="/images/top-banner/img_right_mobile.png"
        width="183"
        height="148"
      />

      <div className="mx-auto flex min-h-[104px] w-full max-w-[1120px] flex-col items-center justify-between lg:flex-row">
        <OptimizedImage
          className="text-0 mx-5 hidden w-max self-end lg:block"
          src="/images/top-banner/img.png"
          width="139"
          height="104"
        />

        <div className="mb-3 lg:mb-0 lg:mr-4">
          <p className="text-center text-[20px] font-medium lg:text-[24px] lg:font-semibold lg:leading-snug">
            ホリデーセール
          </p>
          <p className="m-0 w-full text-center text-lg leading-[1.3] lg:text-[18px] lg:leading-tight">
            お祝いください。お掃除は私たちにお任せ
          </p>
        </div>

        <OptimizedImage
          className="text-0 mr-5 hidden w-max lg:block"
          src="/images/top-banner/img_right.png"
          width="183"
          height="104"
        />

        <a
          className="mb-4 w-full flex-shrink-0 rounded-[10px] bg-[#FB35B5] px-8 py-[11px] text-center text-[16px] font-semibold leading-[1.6] text-white transition-colors duration-200 ease-in-out hover:bg-[#FC53C0] active:bg-[#D52D9A] sm:w-[220px] lg:mb-0"
          href={links.macpawStore}
          onClick={() => handleAnalytics('Click', isMobile)}
          data-banner-cta
        >
          30%割引で取得
        </a>

        <button
          className="block w-full flex-shrink-0 rounded-[10px] border-2 border-[rgba(255,255,255,0.2)] bg-transparent px-8 py-[9px] text-center text-[16px] font-semibold leading-[1.6] text-white transition-colors duration-200 ease-in-out hover:border-[rgba(255,255,255,0.6)] active:border-[rgba(255,255,255,0.5)] sm:w-[220px] lg:hidden"
          type="button"
          onClick={closeOffer}
        >
          を閉じる
        </button>
      </div>
    </aside>
  );
}

export default TopBanner;
