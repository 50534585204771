/* @Components */
import Head from 'next/head';
import Script from 'next/script';
import Layout from '@/components/layout';

/* @Hooks */
import { useCanonical } from '@/hooks/use-canonical';

/* @Providers */
import { IntlProvider } from 'react-intl';

/* @Types */
import type { AppProps } from 'next/app';

/* @Locales */
import locales from 'locales/compiled/ja.json';

/* @Config */
import { GTM_ID, GOOGLE_OPTIMIZE_ID } from 'config/global';

import '../styles/globals.css';

function MyApp({ Component, pageProps }: AppProps) {
  const canonicalLink = useCanonical();

  return (
    <IntlProvider locale="ja" defaultLocale="en" messages={locales}>
      <Head>
        <meta charSet="UTF-8" />
        <meta property="og:site_name" content="CleanMyMac" />
        <meta property="og:type" content="website" />
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="canonical" href={canonicalLink} />
      </Head>
      <Script
        src={`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE_ID}`}
        strategy="beforeInteractive"
      />
      <Script strategy="afterInteractive" id="gtm">
        {`(function(w,d,s,l){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//mtg.cleanmymac.com/mtgcontainer.js?'+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer');`}
      </Script>
      <noscript>
        <iframe
          title="GTM analytics"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          aria-hidden="true"
        />
      </noscript>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </IntlProvider>
  );
}

export default MyApp;
