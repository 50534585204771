import { ReactElement, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useIntl, defineMessages } from 'react-intl';
import { useAnalytics } from '@/hooks/use-analytics';
import { analyticalData } from '@/config/analytics';
import { links } from '@/config/links';

import Crafted from '@/icons/crafted.svg';
import ByMacpaw from '@/icons/by-macpaw.svg';
import ActiveLink from '@/components/active-link';
import OptimizedImage from '@/components/optimized-image';
import TopBanner from '@/components/top-banner';
import { MobileMenu } from '@/components/mobile-menu';
import { Wordmark } from '@/components/icons';
import clsx from 'clsx';

const messages = defineMessages({
  main: {
    defaultMessage: 'main',
    description: 'header menu item',
  },
  blog: {
    defaultMessage: 'blog',
    description: 'header menu item',
  },
  support: {
    defaultMessage: 'support',
    description: 'header menu item',
  },
  getTrial: {
    defaultMessage: 'Get Trial',
  },
  buyNow: {
    defaultMessage: 'Buy now',
  },
});

const menuItems = [
  {
    href: links.internal.main,
    qaAttributeDesktop: 'main-page-header-link-desktop',
    qaAttributeMobile: 'main-page-header-link-mobile',
    message: messages.main,
  },
  {
    href: links.internal.blog,
    qaAttributeDesktop: 'blog-page-header-link-desktop',
    qaAttributeMobile: 'blog-page-header-link-mobile',
    message: messages.blog,
  },
  {
    href: links.internal.support,
    qaAttributeDesktop: 'support-page-header-link-desktop',
    qaAttributeMobile: 'support-page-header-link-mobile',
    message: messages.support,
  },
];

function HeaderNew(): ReactElement {
  const { formatMessage } = useIntl();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isTopBannerClosed, setIsTopBannerClosed] = useState(false);
  const { sendEvent } = useAnalytics();
  const headerRef = useRef<HTMLDivElement>(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
    headerRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (!isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleBuyNowClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onBuyNowClick,
      eventLabel: analyticalData.eventLabel.header,
    });
  };

  const handleGetTrialClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onGetTrialClick,
      eventLabel: analyticalData.eventLabel.header,
    });
  };

  useEffect(() => {
    const isClosed = sessionStorage.getItem('promo-banner') === 'closed';

    setIsTopBannerClosed(isClosed);
  }, []);

  return (
    <>
      {!isTopBannerClosed && <TopBanner bannerType="mobile" onClose={setIsTopBannerClosed} />}
      <div className="sticky top-0 z-[100]">
        {!isTopBannerClosed && <TopBanner bannerType="desktop" onClose={setIsTopBannerClosed} />}

        <header className="z-[100] mx-auto w-full max-w-296 sm:relative sm:px-4 sm:pt-4" ref={headerRef}>
          <div className="flex items-center justify-between bg-white px-5 py-3 shadow-header sm:rounded-5xl sm:px-4 sm:py-0">
            <div className="flex items-center sm:pl-5">
              <Link href={links.internal.main} className="mr-4 flex items-center" data-qa="logo-header-desktop">
                <OptimizedImage
                  className="mr-3 w-8 sm:w-9"
                  src="/images/cmm5-simple.png"
                  alt="CleanMyMac logo"
                  width="36"
                  height="28"
                />

                <Wordmark className="w-28 sm:w-32" />
              </Link>
              <ByMacpaw />
            </div>

            <nav className="hidden sm:block" aria-label="primary">
              <ul className="flex space-x-8">
                {menuItems.map((item) => (
                  <li key={item.href}>
                    <ActiveLink
                      href={item.href}
                      classes={{
                        link: 'link lg:py-6',
                        active: {
                          link: 'link--current',
                        },
                      }}
                      qaAttribute={item.qaAttributeDesktop}
                    >
                      {formatMessage(item.message)}
                    </ActiveLink>
                  </li>
                ))}
              </ul>
            </nav>

            <nav className="hidden lg:block" aria-label="secondary">
              <ul className="flex items-center space-x-4">
                <li>
                  <a
                    href={links.cmmTrial}
                    className="btn-new--secondary btn-new--small btn-new"
                    data-qa="header__get-trial"
                    onClick={handleGetTrialClick}
                  >
                    {formatMessage(messages.getTrial)}
                  </a>
                </li>

                <li>
                  <a
                    className="btn-new--primary btn-new--small btn-new"
                    href={links.cmmStore}
                    data-qa="buy-now-header"
                    onClick={handleBuyNowClick}
                  >
                    {formatMessage(messages.buyNow)}
                  </a>
                </li>
              </ul>
            </nav>

            <MobileMenu className="sm:hidden" handleClick={toggleMobileMenu} isMenuOpen={isMobileMenuOpen} />
          </div>

          <div
            className={clsx(
              'fixed left-0 top-0 -z-10 w-full bg-black/40 sm:hidden',
              isMobileMenuOpen ? 'h-screen' : 'h-0'
            )}
          >
            <div
              className={clsx(
                'fixed bottom-0 left-0 flex w-full flex-col rounded-t-2xl bg-white transition-all duration-300 ease-in-out',
                isMobileMenuOpen ? 'h-90 py-3' : 'h-0'
              )}
            >
              <nav>
                <ul className="mb-2 w-full">
                  {menuItems.map((item, index) => (
                    <li key={item.href} className={clsx(index < menuItems.length - 1 && 'mb-2')}>
                      <ActiveLink
                        onClick={closeMobileMenu}
                        href={item.href}
                        classes={{
                          link: 'link px-5 py-3',
                          active: {
                            root: 'bg-black/5',
                          },
                        }}
                        qaAttribute={item.qaAttributeMobile}
                      >
                        {formatMessage(item.message)}
                      </ActiveLink>
                    </li>
                  ))}
                </ul>
              </nav>

              <nav>
                <ul className="mb-2 flex items-center justify-center px-5 py-3">
                  <li>
                    <a
                      href={links.cmmTrial}
                      className="btn-new--secondary btn-new--small btn-new mr-3"
                      data-qa="header__get-trial"
                      onClick={handleGetTrialClick}
                    >
                      {formatMessage(messages.getTrial)}
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn-new--primary btn-new--small btn-new"
                      href={links.cmmStore}
                      data-qa="buy-now-header"
                      onClick={handleBuyNowClick}
                    >
                      {formatMessage(messages.buyNow)}
                    </a>
                  </li>
                </ul>
              </nav>

              <div className="flex items-center justify-center py-5">
                <Crafted />
                <ByMacpaw />
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export { HeaderNew };
