const analyticalData = {
  eventCategory: 'CleanMyMac 5',
  eventAction: {
    onBuyNowClick: 'Click Buy Now',
    onGetTrialShow: 'Show Get Trial',
    onGetTrialClick: 'Click Get Trial',
    onFormSubscribe: {
      mainPage: 'On-Page Subscribe Form',
    },
    whatAbout: 'What About Block',
  },
  eventLabel: {
    header: 'Header',
    topper: 'Topper',
    floatingBar: 'Floating Bar',
    middleFirst: 'Middle First',
    middleSecond: 'Middle Second',
    article: {
      bottomBanner: 'Blog Article (Bottom Banner)',
      floatingBanner: 'Blog Article (Floating Banner)',
      precontentBanner: 'Blog Article (Before We Start)',
    },
    blog: {
      bottomBanner: 'Blog (Bottom Banner)',
    },
    closer: 'Closer',
    form: {
      impression: 'Impression',
      success: 'Email Opt-In',
    },
    input: {
      empty: 'Subscription Error - Empty',
      invalid: 'Subscription Error - Invalid',
    },
    faq: {
      1: 'Is this paid software?',
      2: 'Is CleanMyMac safe to use?',
      3: 'How does this work?',
    },
  },
};

export { analyticalData };
